import React from "react"
import { Link, graphql } from "gatsby"
import widont from "widont"

import Layout from "../components/layout"
import OffsetGrid from "../components/athena/offsetGrid"
import Image from "../components/image"
import Arrow from "../images/arrow.svg"
import styles from "./works.module.scss"

export const query = graphql`
    query {
        craft {
            page: entry(section: "worksIndex") {
                id
                title
                seomatic {
                    metaTagContainer
                    metaLinkContainer
                    metaTitleContainer
                    metaJsonLdContainer
                }
            }
            works: entries(section: "work") {
                ... on Craft_work_work_Entry {
                    id
                    title
                    slug
                    uri
                    subtitle
                    coverImages {
                        ... on Craft_coverImages_BlockType {
                            ratio_16x9 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_4x3 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_1x1 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_3x4 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const swapElements = (arr, pos1, pos2) => {
  const temp = arr[pos1];
  arr[pos1] = arr[pos2];
  arr[pos2] = temp;
  return arr;
};

const WorkIndexPage = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  let works = data.craft.works
  const aspectRatios = ["1x1", "3x4", "4x3"]


  let arIndex = 0

  // Add all articles to an array that we'll pass to <OffsetGrid />
  const gridItems =
    works.length > 0
      ? // Loop through issues in reverse order
      works
        .slice(0)
        .map((work, i) => {
          // Loop through set aspect ratios
          const numOfAspectRatios = aspectRatios.length - 1
          let ar = aspectRatios[arIndex]
          arIndex = arIndex === numOfAspectRatios ? 0 : ++arIndex
          return (
            <div key={`issue-index-${work.id}`}>
              <Link
                className="img-tile"
                to={`/${work.uri}`}
              >
                <div className="mb-4">
                  {/*
                      This allows us to use the padding-bottom aspect ratio
                      technique so content doesn't jump or get measured incorrectly
                    */}
                  <div
                    className={`img-tile-image img-tile-ar img-tile-ar-${ar}`}
                  >
                    <Image
                      height={work.coverImages[0][`ratio_${ar}`][0].height}
                      width={work.coverImages[0][`ratio_${ar}`][0].width}
                      sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                      url={work.coverImages[0][`ratio_${ar}`][0].url}
                      altText={work.coverImages[0][`ratio_${ar}`][0].altText}
                    />
                  </div>
                </div>
                <div className={styles.issueText}>
                  <div aria-hidden={true} className="subhead fw-bold mb-1">
                    {work.title}
                  </div>
                  <h3 className="h-3">
                      <span className="img-tile-title-underline">
                        {work.subtitle}
                      </span>
                  </h3>
                  <p
                    className="body"
                    dangerouslySetInnerHTML={{
                      __html: widont(work.issueSubtitle),
                    }}
                  />
                  <div className={styles.issueArrow}>
                    <Arrow />
                  </div>
                </div>
              </Link>
            </div>
          )
        })
      : null

  return (
    <Layout seo={seo}>
      <div className="page-content-module">
        <div className="wrapper">
          <div className={styles.works}>
            <OffsetGrid items={gridItems} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WorkIndexPage
